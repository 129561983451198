import React, { useEffect } from 'react';
import './App.css';
import imageOne from './images/one.png';
import imageTwo from './images/two.png';

function App() {
  useEffect(() => {
    const card = document.querySelector('.card');
    let isAutoRotating = true;
    let isFlipped = false;

    function handleClick() {
      if (isAutoRotating) {
        card.classList.add('manual-rotate');
        isAutoRotating = false;
      }
      
      isFlipped = !isFlipped;
      if (isFlipped) {
        card.style.transform = 'rotateY(180deg)';
      } else {
        card.style.transform = 'rotateY(0deg)';
      }
    }

    if (card) {
      card.addEventListener('click', handleClick);
    }

    return () => {
      if (card) {
        card.removeEventListener('click', handleClick);
      }
    };
  }, []);

  return (
    <div className="container">
      <h1 className="title">Something <span className="highlight">Beautiful</span> is Crafting</h1>
      <p className="subtitle">We are going to launch our website <span className="highlight">very soon</span>.<br/>Stay Tune!</p>
      <div className="card-container">
        <div className="card">
          <div className="card-face card-front" style={{ backgroundImage: `url(${imageOne})` }}></div>
          <div className="card-face card-back" style={{ backgroundImage: `url(${imageTwo})` }}></div>
        </div>
        <div className="flip-indicator">
          <i className="fas fa-sync-alt"></i> Click to flip
        </div>
      </div>
      <div className="instagram-text">
        Discover the magic of Del Alma and connect with us on Instagram
      </div>
      <a href="https://www.instagram.com/delalma.in/" className="instagram-button">
        <i className="fab fa-instagram"></i> Del Alma
      </a>
    </div>
  );
}

export default App;